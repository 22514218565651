<template>
  <div class="containerx ma-6">
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row flex-grow-1">
          <div class=" d-flex flex-column flex-grow-1">
            <h2 class=" font font-weight-bold blue-grey--text">
              Stock Levels Overview
            </h2>
            <p class=" font font-weight-medium">
              General overview of monthly stock levels
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" class="mt-n5">
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1" class=" font">
            Stocks Level Overview
          </v-tab>

          <v-tab href="#tab-2" class=" font">
            Stocks Variation (Fuels)
          </v-tab>
          <v-tab href="#tab-3" class=" font">
            Stocks Variation (Lubes)
          </v-tab>

          <!-- <v-tab href="#tab-3" class=" font">
            Deliveries
          </v-tab> -->

          <v-spacer />
          <template v-if="tab !== 'tab-1'">
            <v-chip-group v-model="selectedDuration">
              <v-chip
                v-for="(duration, index) in durations"
                :key="index"
                label
                small
                color="white"
                mandatory
                active-class="primary"
                class=" box-shadow-light font text-uppercase font-weight-medium"
              >
                {{ duration.text }}
              </v-chip>
            </v-chip-group>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              class="font font-weight-medium"
              min-width="auto"
              :nudge-left="120"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-model="dates"
                  v-bind="attrs"
                  v-on="on"
                  label
                  :close="dates.length === 2"
                  @click:close="dates = []"
                  small
                  :color="dates.length > 0 ? 'primary' : 'white'"
                  class=" box-shadow-light mt-2 font text-uppercase font-weight-medium"
                >
                  <i class="material-icons-outlined font-size-md mr-2">event</i>
                  {{ dates.length > 0 ? dateRangeText : "Choose date range" }}
                </v-chip>
              </template>
              <v-date-picker
                class="font font-weight-medium font-size-sm"
                v-model="dates"
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  small
                  class=" font font-weight-medium"
                  text
                  color="primary"
                  @click="(menu = false), (date = [])"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  @click="filteredByDates"
                  class="font font-weight-medium"
                  color="primary"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </template>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <StockLevelsOverview />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <StockSoldOverview
              :currency="currency"
              :period="durations[selectedDuration]"
              :years="years"
              :range="dates"
            />
          </v-tab-item>
          <v-tab-item value="tab-3">
            <StockSoldOverviewLubes
              :currency="currency"
              :period="durations[selectedDuration]"
              :years="years"
              :range="dates"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "StocksIndex",
  computed: {
    ...mapGetters({
      currency: "settings/getSystemCurrency",
      years: "reports/getAllRecordedYears"
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    }
  },
  components: {
    StockLevelsOverview: () => import(`./StockLevelsOverview`),
    StockSoldOverview: () => import(`./StockSoldFuelsOverview`),
    StockSoldOverviewLubes: () => import(`./StockSoldLubesOverview`)
  },
  data() {
    return {
      menu: false,
      dates: [],
      items: ["Stock Level Overview", "Deliveries"],
      durations: [
        { text: "1 week", number: 1, type: "week" },
        { text: "2 weeks", number: 2, type: "weeks" },
        { text: "3 weeks", number: 3, type: "weeks" },
        { text: "1 Month", number: 1, type: "month" }
      ],
      selectedDuration: 0,
      tab: null
    };
  },
  watch: {
    dates(dates) {
      if (dates.length === 2) {
        this.selectedDuration = null;
      } else this.selectedDuration = 0;
    }
  },
  methods: {
    filteredByDates() {
      this.$refs.menu.save(this.dates);
    }
  },
  created() {}
};
</script>
